import React from "react"
import { LegacyCard, Button, Badge, Text } from "@shopify/polaris";
import styled from "styled-components"
const GreyCard = styled.div`
  position: relative;
  .card-container {
    position: relative;
    .img-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #d8d8d8;
      border-radius: 0.8rem;
      z-index: 10;
      opacity: 0.4;
    }
  }
  .upsell-badge {
    text-align: center;
    margin-top: 20px;
    .Polaris-Badge {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.2rem;
    }
  }
`

const CustomizationSelectionCard = (props) => {
  const { title, content, image, url, grey = false, loading = false, plusOnly = false, learnMore = false } = props
  return grey ? (
    <GreyCard>
      <div class="card-container">
        <div class="img-overlay"></div>
        <LegacyCard>
          <LegacyCard.Section>
            <div
              style={{
                maxWidth: "225px",
                height: "Branding and styling" === title ? "260px":"240px",
                position: "relative",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  height: "125px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={image} width={225} />
              </div>
              <div style={{ padding: "15px 0" }}>
                <Text variant="headingSm" as="h3">
                  <Text  variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
                </Text>
              </div>
              <p style={{ paddingBottom: "15px" }}>{content}</p>
            </div>
          </LegacyCard.Section>
        </LegacyCard>
        
      </div>
      { plusOnly &&
        <div className="upsell-badge">
          <Badge tone="success">SHOPIFY PLUS</Badge>
        </div>
        }
    </GreyCard>
  ) : (
    <LegacyCard>
      <LegacyCard.Section>
        <div
          style={{ maxWidth: "225px", height: "Branding and styling" === title ? "260px":"240px", position: "relative" }}
        >
          <div
            style={{
              textAlign: "center",
              height: "125px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={image} width={225} />
          </div>
          <div style={{ padding: "15px 0" }}>
            <Text variant="headingSm" as="h3">
              <Text  variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
            </Text>
          </div>
          <p style={{ paddingBottom: "15px" }}>{content}</p>
          {
            learnMore && 
            <div style={{ position: "absolute", bottom: "3px", left: "0" }}>
            <a onClick={() => learnMore()} style={{textDecoration:'underline', color: '#1A76CF', cursor:'pointer'}}>Learn more</a>
            </div>
          }
          <div style={{ position: "absolute", bottom: "3px", right: "0" }}>
            <Button onClick={() => url()} loading={loading ? true : false}>
              Select
            </Button>
          </div>
        </div>
      </LegacyCard.Section>
    </LegacyCard>
  );
}

export default CustomizationSelectionCard
